@import '../../../UI/global.module.scss';

.container {
	width: 100%;
	margin: 0 auto;
  padding: 88px 56px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 64px;
	background-color: #DAF2F0;

	.suscription {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 24px;

		img {
			width: 180px;
		}

		.text {
			max-width: 540px;
			padding-right: 16px;
			display: flex;
			flex-direction: column;
			gap: 16px;

			.title {
				font-size: 34px;
				font-family: Roca One;
				font-weight: 400;
				line-height: 34px;
				letter-spacing: 1px;				
			}

			.description {
				font-size: 20px;
				font-family: NT Bau;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.5px;				
			}
		}

		.form {
			padding-top: 30px;
			min-height: 132px;
			height: 132px !important;

			.containerInput {
				display: flex;
				gap: 12px;
	
				.input {
					border-radius: 16px;
					height: 60px;
					padding: 16px 34px;
					width: 453px;
					max-width: 374px;
					border: 1px solid #B9BFBD;
					font-size: 18px;
					font-family: NT Bau;
					font-weight: 400;
					line-height: 24px;
					letter-spacing: -0.5px;
				}
				.input::-webkit-input-placeholder {
					font-family: NT Bau;
				}

				&:active {
					border: none;
				}

				.button {
					width: 150px;
					height: 54px;
				}
			}

			
		}		
	}
}

.mainColor {
	color: $main-color;
}

@media only screen and (max-width: 768px) {
	.container {
		padding: 32px 20px;
		width: 100%;
		gap: 40px;
		
		.suscription {
			padding-bottom: 0;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 24px;
			margin: 0 auto;

			img {
				width: 64px;
				margin-bottom: 10px;
			}
			
			.text {
				max-width: 100%;

				.title {
					font-size: 24px;
					line-height: 30px;
					letter-spacing: 0.800000011920929px;			
				}
	
				.description {
					font-size: 16px;
					line-height: 22px;
					letter-spacing: -0.5px;			
				}
			}

			.form {
				width: 100%;
				padding-top: 0;

				.containerInput {
					flex-direction: column;
					width: 100%;
					gap: 28px;
					
					.input {
						width: 100%;
						max-width: 100%;
						border-radius: 12px;
						height: 48px;
						padding: 13px;
						font-size: 16px;
						line-height: 22px;
						letter-spacing: -0.5px;
					}
	
					button {
						width: 100% !important;
						height: 48px !important;
					}
				}
			}
		}
  }
}
